const initBlock = async () => {
  const sections = Array.from(document.getElementsByClassName('b-info-bar'));

  for (const section of sections) {
    const sliderEl = section.querySelector('.swiper');

    if (!sliderEl) {
      continue;
    }

    const {Swiper} = await import('swiper');
    const {Autoplay, FreeMode} = await import('swiper/modules');

    new Swiper(sliderEl, {
      modules: [Autoplay, FreeMode],
      slidesPerView: 'auto',
      spaceBetween: 7,
      draggable: false,
      clickable: false,
      slideToClickedSlide: false,
      preventClicks: false,
      preventClicksPropagation: false,
      allowTouchMove: false,
      autoplay: {
        enabled: true,
        delay: 0,
        pauseOnMouseEnter: false,
        disableOnInteraction: true,
      },
      loop: true,
      noSwipingClass: 'swiper-slide',
      speed: 15000,
    });
  }
};

window.requestAnimationFrame(async function check() {
  document.body ? await initBlock() : window.requestAnimationFrame(check);
});

if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
